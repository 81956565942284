import { AiFillHome, AiFillAudio } from "react-icons/ai";
import { ImBook } from "react-icons/im";
import { BsFillPenFill } from "react-icons/bs";
import { IoHeadsetSharp } from "react-icons/io5";

const getMediaList = async () => {
  try {
    const response = await fetch(
      "https://d1fx0qbs85f2yg.cloudfront.net/mediaListDB.json"
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const mediaListData = await response.json();
    return mediaListData;
  } catch (error) {
    console.error("Error fetching media list:", error);
    return [];
  }
};

export const mediaList = await getMediaList();

export const navLinks = [
  {
    id: "home",
    title: "Home",
    titleAR: "الرئيسة",
    icon: <AiFillHome />,
  },
  {
    id: "biography",
    title: "Biography",
    titleAR: "ترجمة الشيخ",
    icon: <BsFillPenFill />,
  },
  {
    id: "jumuahKhutbahs",
    title: "Jumuah Khutbahs",
    titleAR: "خطب الجمعة",
    icon: <AiFillAudio />,
  },
  {
    id: "audioLectures",
    title: "Audio Lectures",
    titleAR: "الصوتيات",
    icon: <IoHeadsetSharp />,
  },
  {
    id: "books",
    title: "Books",
    titleAR: "الكتب",
    icon: <ImBook />,
  },
];
