import "./App.css";
import React, { useState, useEffect } from "react";
import { Navbar, Footer } from "./Components";
import { Route, Routes } from "react-router-dom";
import {
  HomePage,
  BioPage,
  KhutbahsPage,
  AudioPage,
  BooksPage,
  AddMediaPage,
} from "./Pages";
import { mediaList } from "./Constants/index.js";

function App() {
  const [arabic, setArabic] = useState(() => {
    // Initialize the state based on the value in localStorage
    const storedArabic = localStorage.getItem("arabic");
    return storedArabic === "true";
  });

  // Function to toggle the Arabic state
  const toggleArabic = () => {
    setArabic(!arabic);
  };

  // Use localStorage to save the Arabic state
  useEffect(() => {
    localStorage.setItem("arabic", arabic.toString());
  }, [arabic]);

  // Separate the media list into different lists based on media type
  const audioLecturesList = [];
  const khutbahsList = [];
  const booksList = [];

  mediaList.forEach((media) => {
    if (media.mediaType === "audioLectures") {
      audioLecturesList.push(media);
    } else if (media.mediaType === "khutbahs") {
      khutbahsList.push(media);
    } else if (media.mediaType === "books") {
      booksList.push(media);
    }
  });

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar arabic={arabic} toggleArabic={toggleArabic} />
      <div className="flex-grow min-h-[90vh] pt-20">
        <Routes>
          <Route path="*" element={<HomePage arabic={arabic} />} />
          <Route path="/home" element={<HomePage arabic={arabic} />} />
          <Route path="/biography" element={<BioPage arabic={arabic} />} />
          <Route
            path="/jumuahKhutbahs"
            element={
              <KhutbahsPage arabic={arabic} khutbahsList={khutbahsList} />
            }
          />
          <Route
            path="/audioLectures"
            element={
              <AudioPage
                arabic={arabic}
                audioLecturesList={audioLecturesList}
              />
            }
          />
          <Route
            path="/books"
            element={<BooksPage arabic={arabic} booksList={booksList} />}
          />
          <Route path="/addMedia" element={<AddMediaPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
